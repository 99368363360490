<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Jobs
      </v-card-title>
      <v-spacer></v-spacer>
    </v-row>

    <v-data-table
      class="clickable"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >

      <template v-slot:item.description="{ value }">
        <div class="text-truncate" style="max-width: 250px">{{ value }}</div>
      </template>
    
      <template v-slot:item.status="{ value }">
        <v-chip
          :color="value === 'failed' ? 'red' : (value === 'done' ? 'green' : 'gray')"
          small
        >{{ value }}</v-chip>
      </template>
    
      <template v-slot:item.done_at="{ value }">
        {{ formatDateTime(value) }}
      </template>

      <template v-slot:item.acknowledged="{ value }">
        <v-icon :color="value ? 'secondary' : 'grey'" x-small>fas fa-circle</v-icon>
      </template>
      
    </v-data-table>


    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="primary" dark flat short class="px-2">
          <v-toolbar-title>Job</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>
            <v-text-field
              outlined
              class="mb-2"
              v-model="model.category"
              label="Category"
              readonly
            ></v-text-field>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.description"
              label="Description"
              readonly
            ></v-text-field>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.status"
              label="Status"
              readonly
            ></v-text-field>

            <v-textarea
              outlined
              class="mb-2"
              v-model="model.response"
              label="Response"
              readonly
            ></v-textarea>

            <v-checkbox
              v-model="model.acknowledged"
              :error-messages="errors.acknowledged"
              label="Acknowledged"
              :false-value="0"
              :true-value="1"
              :disabled="!user.can('job.update')"
            ></v-checkbox>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Cancel</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Save</v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref, watch } from 'vue'
import { formatDateTime } from '@/utils'

export default {
  setup() {
    const api = inject('api')
    const { user } = api
    const loader = inject('loader')
    const counterModel = inject('counterModel')

    const jobCollection = api.collection('jobs', {
      params: {
        sort: '-id',
      },
      filter: {
      },
      store: true,
    })

    const { items, filter, totalCount, options, isSearching } = jobCollection

    watch(options, () => {
      counterModel.refresh()
    })

    const headers = [
      { text: 'Category', value: 'category', width: '20%' },
      { text: 'Description', value: 'description', width: '50%'},
      { text: 'Status', value: 'status', width: '10%' },
      { text: 'Done', value: 'done_at', width: '10%' },
      { text: 'Acknowledged', value: 'acknowledged', align: 'center', width: '10%' },
    ]

    const dialog = ref(false)

    const jobModel = api.model('jobs/:id', {
      params: {
      },
    })
    const { attributes: model, errors, isBusy } = jobModel

    loader.bindRef(isBusy)

    function rowClick(item) {
      jobModel.find(item.id).then(() => {
        dialog.value = true
      })
    }

    function saveClick() {
      jobModel.save().then(() => {
        jobCollection.search()
        counterModel.refresh()
        dialog.value = false
      })
    }

    return {
      user,
      headers,
      items,
      filter,
      totalCount,
      options,
      isSearching,
      rowClick,
      saveClick,
      dialog,
      model,
      errors,
      formatDateTime,
    }

  },
}
</script>


<style scoped>

:deep(.v-data-table.clickable table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

</style>
